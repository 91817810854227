.dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dashboard__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  max-width: 1500px;
  height: 100%;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 100px;
}

.dashboard__topline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 65px;
}

.dashboard__toplineHeadline {
  font-size: 60px;
  font-family: "Bebas Neue";
}

.dashboard__toplineCTA {
  background-color: black;
  border-radius: 100px;
  width: 220px;
  height: 50px;
  color: white;
  font-family: "Roboto";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dashboard__board {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard__boardRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-family: "Roboto";
  margin-bottom: 15px;
}

.dashboard__boardRowLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dashboard__boardRowRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dashboard__boardRowID {
  width: 30px;
}

.dashboard__boardRowDate,
.dashboard__boardRowStatus,
.dashboard__boardRowCTA {
  width: 120px;
  text-align: right;
}

.dashboard__boardRowTitle {
  font-weight: 600;
}

.hoverable:hover {
  color: grey;
  cursor: pointer;
}

.Top {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.dashboard__inputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}

.dashboard__inputsInputLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.dashboard__inputsInputRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.dashboard__inputsInputOneLiner {
  width: 30vw;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.dashboard__inputsInputTextarea {
  width: 30vw;
  height: 250px;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.dashboard__inputsInputImg {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.dashboard__inputsInputImgImg {
  width: 100px;
  height: 100px;
}

.dashboard__inputsSubmit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.dashboard__inputsSubmitButton {
  width: 300px;
  height: 50px;
  background-color: black;
  border-radius: 300px;
  border: none;
  color: white;
  font-size: 22px;
  font-family: "Roboto";
  cursor: pointer;
}

.dashboard__collection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  font-size: 16px;
  font-family: "Roboto";
}

.dashboard__collectionInputs {
  width: 100%;
}

.dashboard__collectionInputsTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.dashboard__collectionInputsBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding-bottom: 30px;
}

.dashboard__inputsInputCheckbox {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
