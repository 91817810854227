.navbartop {
  background-color: white;
  height: 81px;
  max-width: 100vw;
  width: 100vw;
  top: 0;
  position: fixed;
  z-index: 99;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbartop__wrapper {
  padding-left: 65px;
  padding-right: 65px;
  width: 1500px;
  height: 81px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: "Bebas Neue";
  font-weight: 400;
  letter-spacing: 0.78;
}

.navbartop__wrapperLeft {
}

.navbartop__wrapperLeftLogo {
  background-image: url("../images/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 185px;
  height: 173px;
  transform: translate(0px, 47px);
}

.navbartop__wrapperCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 44px;
}

.navbartop__wrapperCenterItem {
  width: auto;
}

.navbartop__wrapperRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 110px;
  cursor: pointer;
}

.navbartop__wrapperRightIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 3px;
}

.shadow {
  /* syntax: drop-shadow(nachRechts, nachUnten, unschärfe) rgba(farbe,farbe,farbe, opacity) */
  -webkit-filter: drop-shadow(8px 2px 10px rgba(0, 0, 0, 0.216));
  filter: drop-shadow(8px 2px 10px rgba(0, 0, 0, 0.216));
  /* Similar syntax to box-shadow */
}
