.navbarbottom {
  background-color: black;
  height: 49px;
  max-width: 100vw;
  width: 100vw;
  bottom: 0;
  position: fixed;
  z-index: 99;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbarbottom__wrapper {
  max-width: 1200px;
  width: 95vw;
  height: 81px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 1.1;

  gap: 26px;
}

.navbarbottom__wrapperLeft {
  color: #959595;
  width: 50%;
  text-align: right;
}

.navbarbottom__wrapperRight {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 26px;
  color: #0bfdf4;
}
