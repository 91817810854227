@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://use.typekit.net/buh2hzd.css);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&family=Kaushan+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbartop {
  background-color: white;
  height: 81px;
  max-width: 100vw;
  width: 100vw;
  top: 0;
  position: fixed;
  z-index: 99;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbartop__wrapper {
  padding-left: 65px;
  padding-right: 65px;
  width: 1500px;
  height: 81px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: "Bebas Neue";
  font-weight: 400;
  letter-spacing: 0.78;
}

.navbartop__wrapperLeft {
}

.navbartop__wrapperLeftLogo {
  background-image: url(/static/media/logo.7ea033e2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 185px;
  height: 173px;
  transform: translate(0px, 47px);
}

.navbartop__wrapperCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  grid-gap: 44px;
  gap: 44px;
}

.navbartop__wrapperCenterItem {
  width: auto;
}

.navbartop__wrapperRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 110px;
  cursor: pointer;
}

.navbartop__wrapperRightIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 3px;
}

.shadow {
  /* syntax: drop-shadow(nachRechts, nachUnten, unschärfe) rgba(farbe,farbe,farbe, opacity) */
  -webkit-filter: drop-shadow(8px 2px 10px rgba(0, 0, 0, 0.216));
  filter: drop-shadow(8px 2px 10px rgba(0, 0, 0, 0.216));
  /* Similar syntax to box-shadow */
}

.navbarbottom {
  background-color: black;
  height: 49px;
  max-width: 100vw;
  width: 100vw;
  bottom: 0;
  position: fixed;
  z-index: 99;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.navbarbottom__wrapper {
  max-width: 1200px;
  width: 95vw;
  height: 81px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  font-family: "Roboto";
  font-weight: 400;
  letter-spacing: 1.1;

  grid-gap: 26px;

  gap: 26px;
}

.navbarbottom__wrapperLeft {
  color: #959595;
  width: 50%;
  text-align: right;
}

.navbarbottom__wrapperRight {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 26px;
  gap: 26px;
  color: #0bfdf4;
}

.login__wrapper {
  height: calc(100vh - 162px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login__title {
  font-family: "Bebas Neue";
  font-size: 60px;
}

.login__inputs {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.login__inputsEmailInput,
.login__inputsPasswordInput {
  width: 300px;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.login__inputsSubmitButton {
  width: 300px;
  height: 50px;
  background-color: black;
  border-radius: 300px;
  border: none;
  color: white;
  font-size: 22px;
  font-family: "Roboto";
  cursor: pointer;
}

*,
*:before,
*:after {
  margin: 0pt;
  padding: 0pt;
  box-sizing: border-box;
}

input:disabled {
  background-color: lightgrey !important;
  cursor: not-allowed;
}

/*smaller phone viewpoints */
@media (max-width: 479px) {
  :root {
    --h1-lh: 330px;
    --h1-fs: 50px;
  }
  .home_parallaxTextFlex {
    width: 900px !important;
  }
}
/*small devices and most phones*/
@media (min-width: 480px) and (max-width: 767px) {
  :root {
    --h1-lh: 330px;
    --h1-fs: 100px;
  }
  .home_parallaxTextFlex {
    width: 900px !important;
  }
}
/*most tablets*/
@media (min-width: 768px) and (max-width: 991px) {
  :root {
    --h1-lh: 330px;
    --h1-fs: 200px;
  }
  .home_parallaxTextFlex {
    width: 900px !important;
  }
}
/*smaller desktop*/
@media (min-width: 992px) and (max-width: 1180px) {
  .home_parallaxTextFlex {
    width: 900px;
    height: 670px;
    font-size: 350px;
    line-height: 280px;
  }

  .home__staticContentFirstLeft {
    font-size: 220px;
    line-height: 0.75;
    padding-right: 40px;
    padding-top: 26px;
  }

  .home__staticContentFirstRightLeft {
    max-width: 355px;
  }

  .home__staticContentFirstRightLeftTop {
    font-size: 45px;
    font-weight: 400;
    line-height: 0.9;
    padding-bottom: 20px;
  }

  .home__staticContentFirstRightLeftMiddle {
    font-weight: 500;
    font-size: 25px;
    line-height: 1;
    padding-bottom: 15px;
  }

  .home__staticContentFirstRightLeftBottom {
    font-size: 19px;
    font-weight: 400;
    line-height: 1;
    padding-bottom: 60px;
  }
  .home__staticContentFirstRightRightIcon {
    width: 226px;
    height: 280px;
    font-size: 16spx;
    letter-spacing: -0.5px;
    font-weight: 700;
  }
  .home__staticContentFirstRightRightIconText {
    padding-left: 27px;
    padding-top: 79px;
  }
  .btn-medium {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2px;
    width: 130px;
    height: 50px;
  }
  .home__staticContentSecondRightContentRightTop {
    max-width: 325px;
    max-height: 300px;
    font-size: 55px;
    font-weight: 400;
    line-height: 0.8;
  }
  .home__staticContentSecondRightContentRightBottom {
    padding-top: 20px;
    max-width: 515px;
    max-height: 463px;
    font-size: 17px;
    font-weight: 600;
  }
  .home__staticContentSecondRightContentRightRightImgQuotes {
    width: 30px;
    height: 85px;
    transform: translate(
      -20px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__staticContentSecondPositionFixed {
    right: 30;
    bottom: 30;
    width: 210px;
    height: 203px;
    transform: translate(
      97px,
      -10px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__dynamicContentFirstContentLeftImg {
    margin-top: 40px;
    width: 400px;
    height: 400px;
    border-radius: 1000px;
  }

  .home__dynamicContentFirstContentLeftImgV2 {
    width: 400px;
    height: 400px;
    border-radius: 1000px;
  }

  .home__dynamicContentFirstContentMiddle {
    margin-left: 40px;
    margin-right: 40px;
    width: 380px;
  }

  .home__dynamicContentFirstContentMiddleV2 {
    margin-left: 20px;
    margin-right: 20px;
    width: 380px;
  }

  .home__dynamicContentFirstContentMiddleHeadline {
    min-width: 600px;
    line-height: 0.9;
    padding-bottom: 10px;
    font-size: 74px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }
  .home__dynamicContentFirstContentMiddleHeadlineV2 {
    max-width: 500px;
    line-height: 0.9;
    padding-bottom: 3px;
    font-size: 74px;
    text-transform: uppercase;
    letter-spacing: -0.5px;
    font-family: "Bebas Neue";
    font-weight: 400;
    padding-bottom: 10px;
  }

  .home__dynamicContentFirstContentMiddleHeadlineSubtext {
    font-size: 22px;
    letter-spacing: 0px;
    font-weight: 800;
    line-height: 1;
    padding-bottom: 14px;
  }

  .home__dynamicContentFirstContentMiddleBody {
    font-size: 14px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 22px;
  }

  .home__dynamicContentFirstContentMiddleLink {
    font-size: 14px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.35;
    padding-bottom: 14px;
  }

  .home__dynamicContentFirstContentMiddleSource {
    font-size: 11px;
    letter-spacing: -0.1px;
    font-weight: 300;
    line-height: 1.4;
  }

  .home__dynamicContentFirstContentRightIcon {
    width: 130px;
    height: 150px;
  }

  .home__dynamicContentFirstContentRightIconV2 {
    margin-bottom: 100px;
    width: 130px;
    height: 150px;
  }
  .home__dynamicContentFirstContentRightIconText {
    letter-spacing: -0.5px;
    padding-left: 9px;
    padding-top: 46px;
    font-size: 10px;
  }

  .home__dynamicContentFirstContentRightIconTextV2 {
    letter-spacing: -0.5px;
    padding-right: 3px;
    padding-top: 49px;
    font-size: 10px;
  }

  .home__dynamicContentFirstContentRightIconTextBox {
    width: 80px;
  }

  .btn-small {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 80px;
    height: 32px;
  }

  .home__umfrageText {
    width: 800px;
    height: 310px;
  }

  .home__umfrageTextText {
    font-size: 25px;
    padding-left: 11px;
    width: 430px;
    height: 80px;
    line-height: 1;
  }

  .btn-large {
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 0.6;
    width: 110px;
    height: 55px;
  }

  .home__umfrage {
    height: 290px;
  }

  .home__umfrageKlammerIcon {
    width: 36px;
    height: 76px;
    transform: translate(
      -20px,
      -142px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }

  .home__themeWrapperHeadline {
    padding-top: 30px;
    padding-bottom: 70px;
    font-size: 35px;
    letter-spacing: 0px;
  }

  .home__themeWrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    width: 900px;
  }

  .home__themeWrapperCardsCard {
    max-width: 260px;
  }

  .home__themeWrapperCardsCardImgDivImg {
    width: 260px;
    height: auto;
  }

  .home__themeWrapperCardsCardTitle {
    font-size: 13px;
    letter-spacing: 4.5px;
    padding-top: 12px;
    padding-bottom: 20px;
  }

  .home__themeWrapperCardsCardText {
    padding-bottom: 35px;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: -0.12px;
  }

  .home__overlayBottomFooterWrapper {
    width: 100%;
    padding-left: 65px;
    padding-right: 65px;
  }

  .home__overlayBottomFooterLinks {
    padding-left: 250px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 2px;
  }

  .home__overlayBottomFooterInteresse {
    width: 600px;
    padding-top: 6px;
  }
  .home__overlayBottomFooterInteresseLeft {
    font-size: 30px;
    letter-spacing: 1px;
    padding-right: 30px;
  }
  .home__overlayBottomFooterInteresseRightIcon {
    width: 32px;
    height: 32px;
    transform: translate(5px, -3px);
  }
  .home__overlayBottomPurpleShadowIconBox {
    padding-right: 50px;
    padding-bottom: 100px;
    width: 100%;
  }

  .home__overlayBottomPurpleShadowIcon {
    width: 110px;
    height: 130px;
    transform: translate(0px, 0px);
  }

  .home__overlayBottomPurpleShadowIconText {
    padding-top: 47px;
    padding-left: 6px;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0px;
  }
}
/*large screens*/
@media (min-width: 1181px) {
  .home_parallaxTextFlex {
    padding-left: 65px;
    padding-right: 65px;
    width: 1500px;
    height: 790px;
    font-size: 440px;
    line-height: 350px;
  }

  .home__staticContentFirstLeft {
    font-size: 280px;
    line-height: 0.75;
    padding-right: 40px;
    padding-top: 40px;
  }

  .home__staticContentFirstRightLeft {
    max-width: 434px;
  }

  .home__staticContentFirstRightLeftTop {
    font-size: 65px;
    font-weight: 400;
    line-height: 0.9;
    padding-bottom: 20px;
  }

  .home__staticContentFirstRightLeftMiddle {
    font-weight: 600;
    font-size: 33px;
    line-height: 1;
    padding-bottom: 15px;
  }

  .home__staticContentFirstRightLeftBottom {
    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    padding-bottom: 65px;
  }
  .home__staticContentFirstRightRightIcon {
    width: 321px;
    height: 370px;
    font-size: 19px;
    letter-spacing: -0.5px;
    font-weight: 700;
  }
  .home__staticContentFirstRightRightIconText {
    padding-left: 20px;
    padding-top: 108px;
  }
  .btn-medium {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 3px;
    width: 155px;
    height: 57px;
  }

  .home__staticContentSecondRightContentRightTop {
    max-width: 400px;
    max-height: 420px;
    font-size: 66px;
    font-weight: 400;
    line-height: 0.8;
  }
  .home__staticContentSecondRightContentRightBottom {
    padding-top: 20px;
    max-width: 515px;
    max-height: 463px;
    font-size: 19px;
    font-weight: 600;
  }
  .home__staticContentSecondRightContentRightBottom {
    padding-top: 20px;
    max-width: 515px;
    max-height: 463px;
    font-size: 19px;
    font-weight: 600;
  }
  .home__staticContentSecondRightContentRightRightImgQuotes {
    width: 30px;
    height: 97px;
    transform: translate(
      -20px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__staticContentSecondPositionFixed {
    right: 30;
    bottom: 30;
    width: 260px;
    height: 253px;
    transform: translate(
      123px,
      -10px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__dynamicContentFirstContentLeftImg {
    margin-top: 50px;
    width: 440px;
    height: 440px;
  }

  .home__dynamicContentFirstContentLeftImgV2 {
    width: 440px;
    height: 440px;
  }

  .home__dynamicContentFirstContentMiddle {
    margin-left: 40px;
    margin-right: 40px;
    width: 400px;
  }

  .home__dynamicContentFirstContentMiddleV2 {
    margin-left: 30px;
    margin-right: 30px;
    width: 400px;
  }

  .home__dynamicContentFirstContentMiddleHeadline {
    min-width: 600px;
    line-height: 0.9;
    padding-bottom: 10px;
    font-size: 80px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }
  .home__dynamicContentFirstContentMiddleHeadlineV2 {
    max-width: 500px;
    line-height: 0.9;
    padding-bottom: 10px;
    font-size: 80px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }

  .home__dynamicContentFirstContentMiddleHeadlineSubtext {
    font-size: 22px;
    letter-spacing: 0px;
    font-weight: 800;
    line-height: 1.1;
    padding-bottom: 18px;
  }

  .home__dynamicContentFirstContentMiddleBody {
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 25px;
  }

  .home__dynamicContentFirstContentMiddleLink {
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 400;
    line-height: 1.4;
    padding-bottom: 16px;
  }

  .home__dynamicContentFirstContentMiddleSource {
    font-size: 12px;
    letter-spacing: -0.1px;
    font-weight: 300;
    line-height: 1.4;
  }

  .home__dynamicContentFirstContentRightIcon {
    width: 150px;
    height: 180px;
  }

  .home__dynamicContentFirstContentRightIconV2 {
    margin-bottom: 100px;
    width: 150px;
    height: 180px;
  }
  .home__dynamicContentFirstContentRightIconText {
    letter-spacing: -0.5px;
    padding-left: 10px;
    padding-top: 52px;
    font-size: 13px;
  }

  .home__dynamicContentFirstContentRightIconTextV2 {
    letter-spacing: -0.5px;
    padding-right: 3px;
    padding-top: 55px;
    font-size: 13px;
  }

  .home__dynamicContentFirstContentRightIconTextBox {
    width: 100px;
  }

  .btn-small {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 85px;
    height: 33px;
  }

  .home__umfrageText {
    width: 860px;
    height: 332px;
  }

  .home__umfrageTextText {
    font-size: 27px;
    padding-left: 11px;
    width: 460px;
    height: 90px;
    line-height: 1;
  }

  .btn-large {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.6;
    width: 120px;
    height: 58px;
  }

  .home__umfrage {
    height: 300px;
  }

  .home__umfrageKlammerIcon {
    width: 36px;
    height: 76px;
    transform: translate(
      -20px,
      -147px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }

  .home__themeWrapperHeadline {
    padding-top: 30px;
    padding-bottom: 70px;
    font-size: 40px;
    letter-spacing: 0px;
  }

  .home__themeWrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    width: 1080px;
  }
  .home__themeWrapperCardsCard {
    max-width: 300px;
  }

  .home__themeWrapperCardsCardImgDivImg {
    width: 300px;
    height: auto;
  }

  .home__themeWrapperCardsCardTitle {
    font-size: 13px;
    letter-spacing: 4.5px;
    padding-top: 15px;
    padding-bottom: 22px;
  }

  .home__themeWrapperCardsCardText {
    padding-bottom: 35px;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: -0.12px;
  }
  .home__overlayBottomFooterWrapper {
    width: 100%;
    padding-left: 65px;
    padding-right: 65px;
  }

  .home__overlayBottomFooterLinks {
    padding-left: 250px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 2px;
  }

  .home__overlayBottomFooterInteresse {
    width: 600px;
    padding-top: 6px;
  }
  .home__overlayBottomFooterInteresseLeft {
    font-size: 38px;
    letter-spacing: 1.5px;
    padding-right: 30px;
  }
  .home__overlayBottomFooterInteresseRightIcon {
    width: 32px;
    height: 32px;
    transform: translate(5px, -3px);
  }

  .home__overlayBottomPurpleShadowIconBox {
    padding-right: 50px;
    padding-bottom: 100px;
    width: 100%;
  }

  .home__overlayBottomPurpleShadowIcon {
    width: 110px;
    height: 130px;
    transform: translate(0px, 0px);
  }

  .home__overlayBottomPurpleShadowIconText {
    padding-top: 44px;
    padding-left: 8px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0px;
  }
}

/* specials */
@media (min-width: 1300px) and (max-width: 1500px) {
  .home__staticContentSecondRightContentRightTop {
    max-width: 400px;
    max-height: 420px;
    font-size: 66px;
    font-weight: 400;
    line-height: 0.8;
  }
  .home__staticContentSecondRightContentRightRightImgQuotes {
    width: 30px;
    height: 97px;
    transform: translate(
      -20px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__staticContentSecondPositionFixed {
    right: 30;
    bottom: 30;
    width: 300px;
    height: 293px;
    transform: translate(
      143px,
      -10px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__dynamicContentFirstContentLeftImg {
    margin-top: 60px;
    width: 490px;
    height: 490px;
  }

  .home__dynamicContentFirstContentLeftImgV2 {
    width: 490px;
    height: 490px;
  }

  .home__dynamicContentFirstContentMiddle {
    margin-left: 40px;
    margin-right: 40px;
    width: 450px;
  }

  .home__dynamicContentFirstContentMiddleV2 {
    margin-left: 40px;
    margin-right: 40px;
    width: 450px;
  }
  .home__dynamicContentFirstContentMiddleHeadline {
    min-width: 700px;
    line-height: 0.9;
    padding-bottom: 11px;
    font-size: 88px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }
  .home__dynamicContentFirstContentMiddleHeadlineV2 {
    max-width: 550px;
    line-height: 0.9;
    padding-bottom: 11px;
    font-size: 88px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }

  .home__dynamicContentFirstContentMiddleHeadlineSubtext {
    font-size: 26px;
    letter-spacing: 0px;
    font-weight: 800;
    line-height: 1.1;
    padding-bottom: 20px;
  }

  .home__dynamicContentFirstContentMiddleBody {
    font-size: 18px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 27px;
  }

  .home__dynamicContentFirstContentMiddleLink {
    font-size: 18px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.7;
    padding-bottom: 18px;
  }

  .home__dynamicContentFirstContentMiddleSource {
    font-size: 14px;
    letter-spacing: -0.1px;
    font-weight: 300;
    line-height: 1.4;
  }

  .home__dynamicContentFirstContentRightIcon {
    width: 160px;
    height: 200px;
  }

  .home__dynamicContentFirstContentRightIconV2 {
    margin-bottom: 100px;
    width: 160px;
    height: 200px;
  }
  .home__dynamicContentFirstContentRightIconText {
    letter-spacing: -0.5px;
    padding-left: 15px;
    padding-top: 60px;
    font-size: 14px;
  }

  .home__dynamicContentFirstContentRightIconTextV2 {
    letter-spacing: -0.5px;
    padding-left: 1px;
    padding-top: 62px;
    font-size: 14px;
  }

  .home__dynamicContentFirstContentRightIconTextBox {
    width: 110px;
  }

  .btn-small {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 96px;
    height: 35px;
  }

  .home__umfrageText {
    width: 860px;
    height: 332px;
  }

  .home__umfrageTextText {
    font-size: 27px;
    padding-left: 11px;
    width: 460px;
    height: 98px;
    line-height: 1.1;
  }

  .btn-large {
    font-size: 23px;
    font-weight: 700;
    letter-spacing: 1px;
    width: 135px;
    height: 63px;
  }

  .home__umfrage {
    height: 320px;
  }

  .home__umfrageKlammerIcon {
    width: 36px;
    height: 76px;
    transform: translate(
      -20px,
      -157px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }

  .home__themeWrapperHeadline {
    padding-top: 30px;
    padding-bottom: 70px;
    font-size: 50px;
    letter-spacing: 0px;
  }

  .home__themeWrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    width: 1200px;
  }

  .home__themeWrapperCardsCard {
    max-width: 330px;
  }

  .home__themeWrapperCardsCardImgDivImg {
    width: 330px;
    height: auto;
  }

  .home__themeWrapperCardsCardTitle {
    font-size: 14px;
    letter-spacing: 4.5px;
    padding-top: 15px;
    padding-bottom: 24px;
  }

  .home__themeWrapperCardsCardText {
    padding-bottom: 35px;
    font-weight: 800;
    font-size: 20px;
    letter-spacing: -0.12px;
  }

  .home__overlayBottomFooterWrapper {
    width: 100%;
    padding-left: 65px;
    padding-right: 65px;
  }

  .home__overlayBottomFooterLinks {
    padding-left: 300px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 2px;
  }

  .home__overlayBottomFooterInteresse {
    width: 600px;
    padding-top: 6px;
  }
  .home__overlayBottomFooterInteresseLeft {
    font-size: 40px;
    letter-spacing: 2.5px;
    padding-right: 30px;
  }
  .home__overlayBottomFooterInteresseRightIcon {
    width: 35px;
    height: 33px;
    transform: translate(5px, -1px);
  }

  .home__overlayBottomPurpleShadowIconBox {
    padding-right: 50px;
    padding-bottom: 100px;
    width: 100%;
  }

  .home__overlayBottomPurpleShadowIcon {
    width: 154px;
    height: 190px;
    transform: translate(0px, 0px);
  }

  .home__overlayBottomPurpleShadowIconText {
    padding-top: 65px;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 0px;
  }
}
/*x-large screens*/
@media (min-width: 1500px) {
  .home__staticContentSecondRightContentRightTop {
    max-width: 515px;
    max-height: 463px;
    font-size: 85px;
    font-weight: 400;
    line-height: 0.8;
  }
  .home__staticContentSecondRightContentRightRightImgQuotes {
    width: 30px;
    height: 114px;
    transform: translate(
      -20px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }
  .home__staticContentSecondPositionFixed {
    right: 30;
    bottom: 30;
    width: 380px;
    height: 380px;
    transform: translate(
      176px,
      -20px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }

  .home__dynamicContentFirstContentLeftImg {
    margin-top: 100px;
    width: 600px;
    height: 600px;
  }

  .home__dynamicContentFirstContentLeftImgV2 {
    width: 600px;
    height: 600px;
  }

  .home__dynamicContentFirstContentMiddle {
    margin-left: 48px;
    margin-right: 48px;
    width: 524px;
  }

  .home__dynamicContentFirstContentMiddleV2 {
    margin-left: 48px;
    margin-right: 48px;
    width: 524px;
  }

  .home__dynamicContentFirstContentMiddleHeadline {
    min-width: 800px;
    line-height: 0.9;
    padding-bottom: 16px;
    font-size: 109px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }
  .home__dynamicContentFirstContentMiddleHeadlineV2 {
    max-width: 600px;
    line-height: 0.9;
    padding-bottom: 16px;
    font-size: 109px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-family: "Bebas Neue";
    font-weight: 400;
  }
  .home__dynamicContentFirstContentMiddleHeadlineSubtext {
    font-size: 30px;
    letter-spacing: 0px;
    font-weight: 800;
    line-height: 1.1;
    padding-bottom: 20px;
  }

  .home__dynamicContentFirstContentMiddleBody {
    font-size: 20px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.6;
    padding-bottom: 30px;
  }

  .home__dynamicContentFirstContentMiddleLink {
    font-size: 20px;
    letter-spacing: -0.1px;
    font-weight: 400;
    line-height: 1.7;
    padding-bottom: 20px;
  }

  .home__dynamicContentFirstContentMiddleSource {
    font-size: 15px;
    letter-spacing: -0.1px;
    font-weight: 300;
    line-height: 1.4;
  }

  .home__dynamicContentFirstContentRightIcon {
    width: 197px;
    height: 232px;
  }

  .home__dynamicContentFirstContentRightIconV2 {
    margin-bottom: 100px;
    width: 197px;
    height: 232px;
  }
  .home__dynamicContentFirstContentRightIconText {
    letter-spacing: -0.5px;
    padding-left: 12px;
    padding-top: 70px;
    font-size: 16px;
  }

  .home__dynamicContentFirstContentRightIconTextV2 {
    letter-spacing: -0.5px;
    padding-right: 6px;
    padding-top: 72px;
    font-size: 16px;
  }

  .home__dynamicContentFirstContentRightIconTextBox {
    width: 130px;
  }

  .btn-small {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 106px;
    height: 39px;
  }

  .home__umfrageText {
    width: 950px;
    height: 362px;
  }

  .home__umfrageTextText {
    font-size: 30px;
    padding-left: 11px;
    width: 499px;
    height: 98px;
    line-height: 1.2;
  }

  .btn-large {
    font-size: 27px;
    font-weight: 700;
    letter-spacing: 1px;
    width: 151px;
    height: 72px;
  }

  .home__umfrage {
    height: 362px;
  }

  .home__umfrageKlammerIcon {
    width: 36px;
    height: 76px;
    transform: translate(
      -20px,
      -178px
    ); /* Equal to: translateX(10px) or translate(10px, 0) */
  }

  .home__themeWrapperHeadline {
    padding-top: 34px;
    padding-bottom: 74px;
    font-size: 56px;
    letter-spacing: 0px;
  }

  .home__themeWrapper {
    padding-top: 150px;
    padding-bottom: 150px;
    width: 1400px;
  }

  .home__themeWrapperCardsCard {
    max-width: 407px;
  }

  .home__themeWrapperCardsCardImgDivImg {
    width: 408px;
    height: auto;
  }

  .home__themeWrapperCardsCardTitle {
    font-size: 15px;
    letter-spacing: 4.5px;
    padding-top: 17px;
    padding-bottom: 25px;
  }

  .home__themeWrapperCardsCardText {
    padding-bottom: 35px;
    font-weight: 800;
    font-size: 23px;
    letter-spacing: -0.12px;
  }

  .home__overlayBottomFooterWrapper {
    width: 1500px;
    padding-left: 65px;
    padding-right: 65px;
  }

  .home__overlayBottomFooterLinks {
    padding-left: 410px;
    grid-gap: 30px;
    gap: 30px;
    padding-top: 2px;
  }

  .home__overlayBottomFooterInteresse {
    width: 600px;
    padding-top: 6px;
  }
  .home__overlayBottomFooterInteresseLeft {
    font-size: 53px;
    letter-spacing: 4.5px;
    padding-right: 30px;
  }
  .home__overlayBottomFooterInteresseRightIcon {
    width: 43px;
    height: 40px;
    transform: translate(5px, -3px);
  }

  .home__overlayBottomPurpleShadowIconBox {
    padding-right: 50px;
    padding-bottom: 100px;
    width: 1500px;
  }

  .home__overlayBottomPurpleShadowIcon {
    width: 154px;
    height: 190px;
    transform: translate(0px, 0px);
  }

  .home__overlayBottomPurpleShadowIconText {
    padding-top: 65px;
    padding-left: 10px;
    font-size: 14px;
    letter-spacing: 0px;
  }
  .home__overlayTop {
    background-size: cover !important;
  }
}

@media (max-height: 800px) {
  .home_parallaxTextFlex {
    padding-left: 65px;
    padding-right: 65px;
    width: 1500px;
    height: 680px !important;
    font-size: 360px !important;
    line-height: 300px !important;
  }
}

::selection {
  background: #ffb7b7; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #ffb7b7; /* Gecko Browsers */
}

input {
  -webkit-appearance: none;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.content-pad {
  width: 100%;
}

:focus-visible {
  outline: 0px solid white;
}

.dnone {
  display: none !important;
}

.darkgrey {
  background-color: #323232 !important;
  color: white !important;
}

.stop-scrolling {
  overflow: hidden;
  position: fixed;
  height: 100%;
}

body {
  max-width: 100vw;
  margin-top: 81px;
}

.home {
}

.home__parallaxBackground {
  /* unused 
  background-image: url("./images/home_background.png");

  min-height: 100vh;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  */
}

.home__parallaxText {
  /* The image used */

  /* Set a specific height */
  min-height: 100vh;

  /* Create the parallax scrolling effect */
  z-index: 1;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /*  max-width: 1440px; */
}

.home_parallaxTextFlex {
  color: #ac44cd;
  font-family: "Bebas Neue";
  font-weight: 400;
}

.home__overlayTop {
  z-index: 0;
  min-height: 100vh;
  background-image: url(/static/media/hintergrundTop.aefb0274.png);
  min-width: 100%;
  background-repeat: no-repeat;
  background-size: auto;
}

.home__overlayTopPurpleShadow {
  position: relative;
  height: 100vh;
  width: 100%;
  max-width: 100vw;
}

.home__overlayTopCrowd {
  position: relative;
  z-index: 2;
  min-height: 29vh;
  width: 100%;
  background-color: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(/static/media/Vereinigungsmenge5.2954416b.png);
  transform: translate(0px, 1px);
}
.home__staticCcontent {
}

.home__staticContentFirst {
  position: relative;
  z-index: 2;
  min-height: 110vh;
  background-image: linear-gradient(#f8d1e1, #fe965a);

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home__staticContentFirstBox {
  width: 1500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80vh;
  padding-top: 20vh;
}

.home__staticContentFirstLeft {
  color: #f05ca3;
  text-align: right;
  font-weight: 400;
  font-family: "Bebas Neue";
}

.home__staticContentFirstRight {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.home__staticContentFirstRightLeft {
  /* media querys */
}

.home__staticContentFirstRightLeftTop {
  text-transform: uppercase;
  color: #feeff2;
  font-family: "Bebas Neue";
}

.home__staticContentFirstRightLeftMiddle {
  color: #ffffff;
  font-family: "Roboto";
}

.home__staticContentFirstRightLeftBottom {
  color: #ffffff;
  font-family: "Roboto";
}

.home__staticContentFirstRightRight {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.home__staticContentFirstRightRightIcon {
  background-color: none;
  background-size: cover;
  max-width: 100vw;
  background-image: url("/static/media/Gruppe 381.18308ba8.svg");
  font-style: "Roboto";
  text-align: center;
}
.home__staticContentFirstRightRightIconText {
  /* only media querys */
}

.home__staticContentFirstRightRightIconTextHighlight {
  text-transform: uppercase;
  color: #e9539c;
}

.btn-medium {
  background-color: black;
  color: white;
  font-family: "Roboto";
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: center;
}

.home__staticContentSecond {
  position: relative;
  z-index: 2;
  display: flex;
}

.home__staticContentSecondLeft {
  width: 50vw;
  height: 41vw;
  background-image: url(/static/media/zeitungsartikel.26e19c4f.png);

  background-color: #ac44cd;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
}

.home__staticContentSecondRight {
  width: 50vw;
  height: 41vw;
  background-color: #ac44cd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.home__staticContentSecondRightContent {
  max-width: 600px;
  max-height: 486px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.home__staticContentSecondRightContentLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.home__staticContentSecondRightContentLeftImgQuotes {
  width: 30px;
  height: 18px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAATCAYAAABobNZCAAAABHNCSVQICAgIfAhkiAAAAfRJREFUSEulVj1awkAQnVm+D4RGjhBOYDyBeAKhExqxFAr1BnoCsIiWSgN2xBMQTiA3AE8gNgJ+nxlnlyT8JWGD22bevDezb2aDEHOoWcrjrT2Ji/kPHqPAkpjSub5odI6Tkkusm841hXDbePXqROFDyenp3CBX9ADBFPVOpMCwpL5oHexWYrKqJgH0GZwHoIGod4u6la8SS8wu4Wvk68QeJcEEkBxEeohrocIi9QDQWBdLNpPYWO+2N4sIyEOJN6KJ4CXV6FxuJtHBchcdnE/LqwZW5F67RotWx58wAe5jZcgVH+3CAsFw1cCK/NeqtBDxeifYC0CgMrfRVsKtao0QnnWxLP6eu3cn4xU5K+dZxkPdBFzBG1dQ8rB6VQfJacwmLijyhVHgXZvYC5ROVteVyX0mxSK6BTbvGHmmi0SinzSBIt8Ty+SncnL2TuCRGyx8lFT4knyf1hF88J0be/mFQf7yWRjOqtpsvTPdClYdm3RSCKidqndrgdvVLieUrt3teK4af75Nf1ksTJcda2GBvnhTmtJsAflyXqkVn4TBhEVsdFjo8nir1UmK3d7tqAScbF6BbJeYT2+i3nf1jGayLQS82L4+GnDFNb9i/3vkkwqEJgcZvAmGMJsNdX8qpAg4ODBdVxT5PXfisIneal1D6sb9Aes2DCPFPAP+AAAAAElFTkSuQmCC);
  background-size: cover;
  margin-right: 5px;
}

.home__staticContentSecondRightContentRightTop {
  font-family: "Bebas Neue";
  color: #e5d1dd;
  text-transform: uppercase;
}
.home__staticContentSecondRightContentRightBottom {
  font-family: "Roboto";
  color: black;
}

.home__staticContentSecondRightContentRightRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}

.home__staticContentSecondRightContentRightRightImgQuotes {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAATCAYAAABobNZCAAAABHNCSVQICAgIfAhkiAAAAd9JREFUSEutVjFWwkAQnQ/vWdiIJxBvgCcQTiB2kEYshQI8gXICbAKl2ICduQF4Az2BcAM6nwUZZzckhCWEjZgqb7Mz/8/Mn5mAdjzsOiUCn+jPP9+fuPcWu+6a5xu24DnuXmdJtjAPl67zKIdtAhU2v7EHRhet0UcK4QaDH4hQNGynAHeFxDR+HoFzr1rgo+OJgJbSIgTTrRAYbpHu14cg3GSxjcD9fl1Y4dImtULgIp6BpVt/AtC2siW+RnPsqbsanPv1KhPebIyDOzzNNccV/TaoFZlzX9a2TItca3Qagfuu4wmNK2sHivUq+ixRh/6xil5HLikXJSNQtuUT1j5LuULXzNTNt0ZK2ArcYUvM6Fro4HBw15lJ2s+yEPi3yJcWbWISA/xzNTxErB0Ray8TcfgV1fOB2ge1sih2Yu+A30Xt5bXaIYPHUjNMc1F7MVK7esmiWrPPZZzKZKNnG/Kh0jfAbQnsmnA2BJj4Jd8cN9YtZ9DVJfBznaS+3wUctZAsIx/ckVpWzTKYwFuRm2mLt9E+YNNWLyiQLBk9kBL3wdZWizsJwKkk26ycts2Sah1OzTTS6eDS/yqFWYH14JKRLWt0GC6RJIKp4Oqn4C/AugVlRe/7AUkFt2mdQ+78Aqwu8RTAF2fqAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
}

.home__staticContentSecondPositionFixed {
  background-image: url(/static/media/apfel.c9836018.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.home__dynamicContent {
  position: relative;
  z-index: 2;
  background-color: white;
  padding-top: 150px;
  padding-bottom: 150px;
}

.home__dynamicContentFirst {
  padding-top: 100px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.home__dynamicContentFirstContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  max-width: 1500px;
}

.home__dynamicContentFirstContentLeftImg {
}

.home__dynamicContentFirstContentMiddle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.home__dynamicContentFirstContentMiddleV2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.home__dynamicContentFirstContentMiddleHeadline {
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  color: #242424;
  text-align: left;
  flex: 0 0 auto;
}

.home__dynamicContentFirstContentMiddleHeadlineV2 {
  z-index: 3;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  flex: 0 0 auto;
}

.home__dynamicContentFirstContentMiddleHeadlineSubtext {
  text-align: left;
  font-family: "Roboto";
  color: #242424;
}

.home__dynamicContentFirstContentMiddleBody {
  color: #707070;
  text-align: left;
  font-family: "Roboto";
}

.bold {
  font-weight: 800;
}

.paddingTop40 {
  margin-top: 40px;
}

.home__dynamicContentFirstContentMiddleLink {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #707070;
  font-family: "Roboto";
  text-align: left;
}

.home__dynamicContentFirstContentMiddleSource {
  color: #8e8e8e;
  text-align: left;
  font-family: "Roboto";
}

.home__dynamicContentFirstContentRight {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.home__dynamicContentFirstContentRightIcon {
  background-color: none;
  background-size: cover;
  background-image: url(/static/media/bubble-right.4479ebcb.svg);
}

.home__dynamicContentFirstContentRightIconV2 {
  background-color: none;
  background-size: cover;
  background-image: url(/static/media/bubble-left.49d5bd7f.svg);
}

.home__dynamicContentFirstContentRightIconText {
  font-weight: 700;
  color: #242424;
  font-style: "Roboto";
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home__dynamicContentFirstContentRightIconTextV2 {
  font-weight: 700;
  color: #242424;
  font-style: "Roboto";
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home__dynamicContentFirstContentRightIconTextHighlight {
  color: white;
}

.btn-small {
  background-color: black;
  color: white;
  font-family: "Roboto";
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.marginLeft20 {
  margin-left: 20px;
}

.home__umfrage {
  position: relative;
  z-index: 2;
  background-image: linear-gradient(to right, #fc7d7a, #f958a9);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.home__umfrageKlammerIcon {
  background-image: url(/static/media/klammerTuerkis.928be92a.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.home__umfrageText {
  background-image: url(/static/media/Umfrage.cfd93872.png);
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.home__umfrageTextText {
  font-family: "Bebas Neue";
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.home__umfrageTextButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
}

.btn-large {
  background-color: black;
  color: white;
  font-family: "Roboto";
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
}

.home__umfrageTextErgebnis {
  color: white;
  font-family: "Roboto";
  border-radius: 200px;
  font-size: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.home__theme {
  position: relative;
  z-index: 2;
  background-color: #f0f0f0;
  background-size: cover;
  background-image: url(/static/media/structuredBackground.9c367e44.jpg);

  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
}

.home__themeWrapper {
}

.home__themeWrapperHeadline {
  font-family: "Bebas Neue";
  font-weight: 400;
  color: #242424;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.home__themeWrapperCards {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: row;
}

.home__themeWrapperCardsCard {
  padding-bottom: 46px;
}

.home__themeWrapperCardsCardImgDiv {
}

.home__themeWrapperCardsCardImgDivImg {
  /* media querys only */
}

.home__themeWrapperCardsCardTitle {
  font-family: "Roboto";
  color: #242424;
  text-transform: uppercase;
  font-weight: 300;
}

.home__themeWrapperCardsCardText {
  font-family: "Roboto";
  color: #242424;
}

.home__themeWrapperCardsCardCTA {
}

.home__overlayBottom {
  z-index: 0;
  min-height: calc(100vh - 81px);
  background-image: url("/static/media/Pfad 674.a316e36a.png");
  max-width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
}

.home__overlayBottomFooter {
  position: relative;
  z-index: 2;
  height: 86px;
  max-width: 100vw;
  background-color: #fd9061;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.home__overlayBottomFooterWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.home__overlayBottomFooterLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.home__overlayBottomFooterLinksLink {
  font-family: din-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #041921;
  text-transform: uppercase;
  letter-spacing: 4.5px;
  font-size: 15px;
}

.home__overlayBottomFooterInteresse {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.home__overlayBottomFooterInteresseLeft {
  font-family: "Bebas Neue";
  color: #242424;
  text-transform: uppercase;
  font-weight: 600;
}

.home__overlayBottomFooterInteresseRight {
}

.home__overlayBottomFooterInteresseRightIcon {
  background-image: url("/static/media/Gruppe 288.e18661bf.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.home__overlayBottomPurpleShadow {
  position: relative;
}

.home__overlayBottomPurpleShadowWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  height: calc(100vh - 167px);
  z-index: 2;
  position: relative;
}

.home__overlayBottomPurpleShadowIconBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.home__overlayBottomPurpleShadowIcon {
  background-image: url("/static/media/Gruppe 291.695e2247.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.home__overlayBottomPurpleShadowIconText {
  font-family: "Roboto";
  font-weight: 600;
  color: #242424;
  text-align: center;
}

.dashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dashboard__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  max-width: 1500px;
  height: 100%;
  padding-left: 65px;
  padding-right: 65px;
  padding-bottom: 100px;
}

.dashboard__topline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 65px;
}

.dashboard__toplineHeadline {
  font-size: 60px;
  font-family: "Bebas Neue";
}

.dashboard__toplineCTA {
  background-color: black;
  border-radius: 100px;
  width: 220px;
  height: 50px;
  color: white;
  font-family: "Roboto";
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dashboard__board {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dashboard__boardRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-family: "Roboto";
  margin-bottom: 15px;
}

.dashboard__boardRowLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.dashboard__boardRowRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.dashboard__boardRowID {
  width: 30px;
}

.dashboard__boardRowDate,
.dashboard__boardRowStatus,
.dashboard__boardRowCTA {
  width: 120px;
  text-align: right;
}

.dashboard__boardRowTitle {
  font-weight: 600;
}

.hoverable:hover {
  color: grey;
  cursor: pointer;
}

.Top {
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.dashboard__inputs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.dashboard__inputsInputLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.dashboard__inputsInputRight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}

.dashboard__inputsInputOneLiner {
  width: 30vw;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.dashboard__inputsInputTextarea {
  width: 30vw;
  height: 250px;
  border-top: none;
  border-bottom: 2px solid black;
  border-right: none;
  border-left: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 20px;
  font-family: "Roboto";
  border-radius: 0;
  -webkit-border-radius: 0;
  background-color: rgb(241, 241, 241);
}

.dashboard__inputsInputImg {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.dashboard__inputsInputImgImg {
  width: 100px;
  height: 100px;
}

.dashboard__inputsSubmit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.dashboard__inputsSubmitButton {
  width: 300px;
  height: 50px;
  background-color: black;
  border-radius: 300px;
  border: none;
  color: white;
  font-size: 22px;
  font-family: "Roboto";
  cursor: pointer;
}

.dashboard__collection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  font-size: 16px;
  font-family: "Roboto";
}

.dashboard__collectionInputs {
  width: 100%;
}

.dashboard__collectionInputsTop {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.dashboard__collectionInputsBottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 10px;
  gap: 10px;
  padding-bottom: 30px;
}

.dashboard__inputsInputCheckbox {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

